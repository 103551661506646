<template>
    <item-card :url="`/stock/procurement_return_acts/${item.id}`" contentClass="grid grid-cols-12">
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                {{item.supplier}}
            </h3>
        </template>

        <template v-slot:content>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="UUID" :text="item.uuid" />
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="Datums" :text="item.procurement_date" />
            </div>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="Atgriezšanas datums" :text="item.return_date" />
            </div>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="Kopā EUR" :text="item.total" />
            </div>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="PVN EUR" :text="item.vat" />
            </div>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
                <item-text title="Summa EUR" :text="item.total_with_vat" />
            </div>

        </template>
    </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ProcurementReturnActListItem",
  components: {
    ItemCard,
    ItemText,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
}
</script>

<style>

</style>