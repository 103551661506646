<template>
  <Content :filter="false">

    <template v-slot:content>
        <template v-if="procurementsReturnActs && procurementsReturnActs.data.length > 0">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <template v-for="item in procurementsReturnActs.data" :key="item.id">
                <ProcurementReturnActListItem :item="item" />
              </template>
            </ul>
          </div>

          <Pagination :meta="procurementsReturnActs.meta" :onPageChange="onPageChange" />
        </template>

        <template v-else>
            <p class="py-6 text-center dark:text-gray-300">
                Diemžēl nekas netika atrasts
            </p>
        </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";

import ProcurementReturnActListItem from "@/components/Stock/ProcurementReturnActs/ProcurementReturnActListItem"
import Pagination from "@/components/Components/Pagination";

export default {
  name: "ProcurementReturnActs",
  components: {
    Pagination,
    ProcurementReturnActListItem,
  },
  data: () => ({
    queryParams: null,
  }),
  created() {
    this.$store.dispatch("setDataLoading", true)

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if(Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    page: Search.getCurrentPage(),
                    q: this.search
                }
            })
        }

        this.searchProcurements()
  },
  mounted() {
    this.$store.dispatch('resetSearch')
  },
  computed: {
    ...mapGetters({
        procurements: 'procurements',
        procurementsReturnActs: 'procurementsReturnActs',
        mainCustomer: 'systemMainCustomer',
        search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchProcurements()
    },
  },
  methods: {
    getItems() {
        this.$router.replace({
            query: {
                page: 1,
                q: this.queryParams.q
            }
        })

        this.queryParams = this.$route.query
        this.searchProcurements()
    },

    searchProcurements: debounce(function () {
      this.$store.dispatch('filterProcurements', this.queryParams)
      this.$store.dispatch('getProcurementReturnActs', this.queryParams)
    }, 500),
    onPageChange(page) {
      this.searchProcurements()
    },
    viewProcuerment(procurementId) {
      this.$router.push({path: '/stock/procurements/' + procurementId})
    },
  }
}
</script>

<style>

</style>